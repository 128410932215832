import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from './success.json';
const SupermarketCart = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <section className='main'>
                <div className='Successfull_Lottie_icon'>
                    <Lottie className="me-auto"
                        options={defaultOptions}
                    />
                </div>
            </section>
        </>
    )
}

export default SupermarketCart