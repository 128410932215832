import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "./Edit_Address_Modal.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";

function Edit_Address_Modal(props) {
  const [modalShow, setModalShow] = useState(false);
  const hideModalWithDelay = () => {
    setTimeout(() => {
      window.location.href = "/account-address"; // Change window location to root route ("/")
      setModalShow(false);
    }, 3000); // Change the delay time as needed (3000 milliseconds = 3 seconds)
  };
  const { message } = props; // Extracting 'message' prop
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  return (
    <>
      <div className="Successfull_Modal_sec  account-information my-account ">
        <Modal
          className="Successfull_Modal Edit_Address_Modal"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="form-section">
              <div className="row justify-content-center">
                <div className="col-xxl-10 col-lg-6 mb-3">
                  <div className="account-form">
                    <div className="row mb-3">
                      <div className="col-md-11 col-10">
                        <h4 className="form-title">Edit address</h4>
                      </div>
                    </div>

                    <Form>
                      <div className="Account_Address ">
                        <div className=" checkout-inner-steps">
                          <div className="address_input">
                            <div className="flex-radio-input">
                              <Form.Label>Name of address*</Form.Label>
                              <div className="address-name-select">
                                <Select
                                  classNamePrefix={"selectOptions"}
                                  options={options}
                                  placeholder="--- please select ---"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="address_input">
                            <div className="flex-radio-input">
                              <Form.Label>Flat no./House/bldg*</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=" Flat no./House/bldg"
                              />
                            </div>
                          </div>

                          <div className="address_input">
                            <div className="flex-radio-input">
                              <Form.Label>Colony/street/landmark</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=" Colony/street/landmark"
                              />
                            </div>
                          </div>

                          <div className="address_input">
                            <div className="flex-radio-input">
                              <Form.Label>Zip code*</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=" Zip code"
                              />
                            </div>
                          </div>

                          <div className="address_input">
                            <div className="flex-radio-input">
                              <Form.Label>Mobile number*</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=" Mobile number"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>

                    <div className="mt-5">
                      <Common_Button
                        onClick={() => {
                          setModalShow(true);
                          props.onHide();
                          hideModalWithDelay(); // Call hideModalWithDelay function when showing modal
                        }}
                        className={"SubmitBtn"}
                        text={"Update Address"}
                        
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <Successfull_Modal
        message=" Address Edit successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />




    </>
  );
}

export default Edit_Address_Modal;
