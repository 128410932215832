export const Errors = {
  email: "Email is required",
  email_verify: "Verify your email",
  email_invalid: "Email is invalid",
  phone: "Mobile Number is required",
  phone_verify: "Verify your mobile",
  first_name: "First name is required",
  last_name: "Last name is required",
  gst: "GST is required",
  gst_invalid: "GST number is invalid",
  pan: "PAN number is required",
  pan_invalid: "PAN number is invalid",
  designation: "Designation is required",
  firmName: "Firm name is required",
  firmType: "Select firm type",
  addressType: "Address Type is required",
  address_line_one: "Address is required",
  address_line_two: "Address is required",
  city: "City is required",
  state: "State is required",
  country: "Country is required",
  address: "Enter Address",
  name: "Enter name",
  industryTypes: "Enter types",
  subCategory: "Enter subCategory",
  transport_no: "",
  service_area_id: "select service type",
  category: "Please select category",
  sub_category: "Please select sub category",
  shipment_id: "Please select Shipment",
};

export const Placeholders = {
  email: "Enter Email",
  phone: "Enter Mobile Number",
  first_name: "Enter First name",
  last_name: "Enter Last name",
  gst: "Enter GST",
  pan: "Enter PAN number",
  designation: "Enter Designation",
  firmName: "Enter Firm Name",
  firmType: "Select Firm Type",
  address_line_one: "Enter Address",
  address_line_two: "Enter Address",
  city: "Select City",
  state: "Select State",
  country: "Select Country",
  address: "Enter Address",
  name: "Enter Name",
  industryTypes: "Enter types",
  subCategory: "Enter subCategory",
  address_name: "Enter Address name",
};
