import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import { Context } from "../../../../utils/context";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../ScrollTotop/ScrollTotop";

function Stepthree(props) {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    watch,
  } = useForm();
  const { getData, IMG_URL, Select2Data, postData } = useContext(Context);
  const [user_id, setUserId] = useState("");

  // Watch specific fields
  const companyName = watch("company_name", "");
  const takePercent = watch("take_percent", "");
  const givePercent = watch("give_percent", "");

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (data?.id) {
      formData.append("id", data?.id);
    }
    formData.append("user_id", user_id);
    formData.append("company_name", data?.company_name);
    formData.append("take_percent", data?.take_percent);
    formData.append("give_percent", data?.give_percent);

    try {
      const res = await postData("/seller/s-commission_details", formData);
      if (res?.success) {
        props.nextStep();
        navigate("/");
      } else {
        console.error("Failed to submit data");
      }
    } catch (error) {
      console.error("An error occurred while submitting data:", error);
    }
  };

  const getEditData = async () => {
    const res = await getData(`/seller/s-commission_details/${user_id}`);
    if (res?.success) {
      reset(res?.data);
    }
  };

  useEffect(() => {
    if (user_id) {
      getEditData();
    }
  }, [user_id]);

  useEffect(() => {
    setUserId(Cookies.get("user_id"));
  }, []);

  return (
    <>
      <ScrollToTop />
      <section className="personal-details-form userForm">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="details-form-holder">
            <div className="form-container text-center">
              <FontAwesomeIcon
                icon="fa-solid fa-triangle-exclamation"
                className="trianglealertmark"
              />
              <p>
                I am getting {`${takePercent}%`} from ({`${companyName}`}) and I
                can give {`${givePercent}%`} from my profit margin.
              </p>
              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required">
                    Enter Account Number*
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="company_name"
                    placeholder="Company Name"
                    {...register("company_name", {
                      required: "Company Name is required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.company_name,
                      "is-valid":
                        !errors?.company_name && getValues("company_name"),
                    })}
                  />
                  {errors.company_name && (
                    <span className="text-danger">
                      {errors.company_name.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required">
                    Incoming Profit Margin
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="take_percent"
                    placeholder="Incoming Profit Margin"
                    {...register("take_percent", {
                      required: "Incoming Profit Margin is required",
                      min: {
                        value: 0,
                        message: "Value must be greater than  0", // Error message for negative values
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.take_percent,
                      "is-valid":
                        !errors?.take_percent && getValues("take_percent"),
                    })}
                  />
                  {errors.take_percent && (
                    <span className="text-danger">
                      {errors.take_percent.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required">
                    Profit I Can Give
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="give_percent"
                    placeholder="Profit I Can Give"
                    {...register("give_percent", {
                      required: "Profit I Can Give is required",
                      min: {
                        value: 0,
                        message: "Value must be greater than  0", // Error message for negative values
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.give_percent,
                      "is-valid":
                        !errors?.give_percent && getValues("give_percent"),
                    })}
                  />
                  {errors.give_percent && (
                    <span className="text-danger">
                      {errors.give_percent.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="text-end apply_now_btn">
              <div className="registerContinueMain">
                <Button onClick={props.prevStep} className="back-btn me-3">
                  Back
                </Button>
                <Button className="tabs-btn" type="submit">
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </>
  );
}

export default Stepthree;
