import React from "react";
import "./Veiw_All_Card.css";
import { Card } from "react-bootstrap";

function Veiw_All_Card() {
  return (
    <>
      <div className="veggies-section Veiw_All_Card">
        <Card className="veggies Veiw_All_Card_content">
          <div className="view-text-holder">
            <p>View All</p>
          </div>
        </Card>
      </div>
    </>
  );
}

export default Veiw_All_Card;
