import React, { useState, useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import classNames from "classnames";
import Cookies from "js-cookie";
import { Context } from "../../../../utils/context";
import { useForm } from "react-hook-form";
import ScrollToTop from "../../../ScrollTotop/ScrollTotop";

const Stepnine = (props) => {
  const [show, setShow] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(
    "https://www.clickdimensions.com/links/TestPDFfile.pdf"
  ); // Replace with the actual path to the admin-uploaded PDF
  const [uploadedPdfUrl, setUploadedPdfUrl] = useState(null);
  const [adminPdfName, setAdminPdfName] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file type is PDF
      if (file.type === "application/pdf") {
        const fileUrl = URL.createObjectURL(file);
        setUploadedPdfUrl(fileUrl);
      } else {
        // Notify the user that only PDF files are allowed
        alert("Please upload a PDF file.");
      }
    }
  };

  useEffect(() => {
    const fileName = pdfUrl.split("/").pop();
    setAdminPdfName(fileName);
  }, [pdfUrl]);

  const { getData, IMG_URL, postData } = useContext(Context);
  const [user_id, setUserId] = useState("");

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (data?.id) {
      formData.append("id", data?.id);
    }
    formData.append("user_id", user_id);
    formData.append("document", data?.document[0]);

    try {
      const res = await postData("/seller/s-onboarding-document", formData);
      if (res?.success) {
        props.nextStep();
      } else {
        if (res?.message?.pan_no) {
          setError("document", { message: res?.message?.document });
        }
      }
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("An error occurred while submitting data:", error);
    }
  };

  const getEditData = async () => {
    const res = await getData(`/seller/s-onboarding-document/${user_id}`);
    if (res?.success) {
      reset(res?.data);
    }
  };

  useEffect(() => {
    getEditData();
  }, [user_id]);

  useEffect(() => {
    setUserId(Cookies.get("user_id"));
  }, []);

  return (
    <>
      <ScrollToTop />
      <section className="personal-details-form">
        <div className="form-container">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="Stepnine">
              <div className="row itemDetails">
                <div className="col-lg-6">
                  <Form.Label>View Document</Form.Label>
                  <div className="">
                    <Form.Control
                      type="text"
                      className="me-3"
                      value={adminPdfName}
                      disabled
                    />
                  </div>

                  <div className="row">
                    <div className="col-md-7">
                      <div className="view-pdf-holder">
                        {uploadedPdfUrl ? (
                          <iframe
                            className="pdf-holder"
                            src={uploadedPdfUrl}
                            title="Uploaded PDF"
                          ></iframe>
                        ) : (
                          pdfUrl && (
                            <iframe
                              className="pdf-holder"
                              src={pdfUrl}
                              title="Admin Uploaded PDF"
                            ></iframe>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="col-md-6">
                    <div className="form-field mb-4">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required">
                          Upload Document
                        </Form.Label>
                        <div>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.document,
                            })}
                            type="file"
                            {...register("document", {
                              validate: async (value) => {
                                if (typeof value !== "string") {
                                  const fileTypes = ["pdf"];
                                  const fileType =
                                    value[0]?.name?.split(".")[1];

                                  if (!fileTypes.includes(fileType)) {
                                    return `please upload a valid file format. (${fileTypes})`;
                                  }
                                }
                              },
                            })}
                            accept=".pdf"
                            multiple={false}
                            onChange={handleFileUpload}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end apply_now_btn">
              <div className="registerContinueMain">
                <Button onClick={props.prevStep} className="back-btn me-3">
                  Back
                </Button>
                <Button className="tabs-btn" type="submit">
                  Next
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </section>
    </>
  );
};

export default Stepnine;
