import React, { useState, useEffect, useContext } from "react";
import "./OneAdress.css";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import {
  faLocation,
  faLocationDot,
  faPen,
  faPhone,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";

import { Context } from "../../../../utils/context";

import Cookies from "js-cookie";

import {
  Country,
  State,
  City,
  Pincode,
} from "../../../../utils/apis/master/Master";
import { Errors } from "../../../../utils/errors";
import { RegxExpression } from "../../../../utils/common";

import {
  getAddress,
  postAddress,
  getSingleAddress,
  DeleteAddress,
} from "../../../../utils/apis/orderProcess/orderProcess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Successfull_Modal from "../../../Common_modal/Successfull_Modal/Successfull_Modal";
import Delete_Address from "../../../Common_modal/Delete_Address/Delete_Address";
import Please_Add_Products_Modal from "../../../Common_modal/Please_Add_Products_Modal/Please_Add_Products_Modal";
import Please_add_address from "../../../Common_modal/please_add_address/please_add_address";
import ScrollToTop from "../../../ScrollTotop/ScrollTotop";
const OneAdress = ({
  handleNext,
  setAddressId,
  addressId,
  setAddressData,
  addressData,
}) => {
  const options = [
    { value: "Home", label: "Home" },
    { value: "Work", label: "Work" },
    { value: "Office", label: "Office" },
  ];

  const { Select2Data, setDeliveryPincode, deliveryPincode } =
    useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    setValue,
  } = useForm();

  const [modalShow, setModalShow] = useState(false);
  const [button, setButton] = useState("Save Address");
  const [successfulAddress, setSuccessfulAddress] = useState(
    "Address Added successfully"
  );

  const [modalShowAddProduct, setModalShowAddProdct] = React.useState(false);
  const [modalDeleteAddressShow, setModalDeleteAddressShow] = useState(false);
  const [modalEditAddressShow, setModalEditAddressShow] = useState(false);
  const [address, setAddress] = useState([]);
  const onSubmit = async (data) => {
    const sendData = {
      address_type: data?.address_type?.value,
      name: data?.name,
      mobile_number: data?.mobile_number,
      flat_home_bldg: data?.flat_home_bldg,
      col_land_strt: data?.col_land_strt,
      country_id: data?.country_id?.value,
      state_id: data?.state_id?.value,
      city_id: data?.city_id?.value,
      pincode_id: data?.pincode_id?.value,
    };

    if (data?.id) {
      sendData.id = data?.id;
    }
    const res = await postAddress(sendData);
    if (res?.success) {
      setModalShow(true);
      addressGet();
      hideModalWithDelay();
      setButton("Save Address");
      reset();
      setValue("address_type", "");
      setValue("name", "");
      setValue("flat_home_bldg", "");
      setValue("col_land_strt", "");
      setValue("mobile_number", "");
      setValue("mobile_number", "");
      setValue("country_id", "");
      setValue("state_id", "");
      setValue("pincode_id", "");
      setValue("address_type", "");
      setValue("id", "");
    }
  };

  const hideModalWithDelay = () => {
    setTimeout(() => {
      setModalShow(false);
    }, 3000); // Change the delay time as needed (3000 milliseconds = 3 seconds)
  };

  const addressGet = async () => {
    const res = await getAddress();
    if (res?.success) {
      setAddress(res?.data);
      if (!addressId) {
        setAddressId(res?.data[0]?.id);
        setAddressData(res?.data[0]);
      }
    }
  };

  const addressDelete = async (id) => {
    const res = await DeleteAddress(id);
    if (res?.success) {
      addressGet();
    }
  };

  const [addId, setAddId] = useState("");

  const [countries, setCountries] = useState();
  const [states, setState] = useState();
  const [cities, setCity] = useState();
  const [pincodes, setPincode] = useState();
  const getCountries = async () => {
    const res = await Country();
    if (res?.success) {
      const data = await Select2Data(res?.data, "country_id", false);
      setCountries(data);
    }
  };
  const getState = async (id) => {
    const res = await State(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, "state_id", false);
      setState(data);
      setValue("state_id", "");
      setValue("city_id", "");
      setValue("pincode_id", "");
    }
  };
  const getCity = async (id) => {
    const res = await City(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, "city_id", false);
      setCity(data);
      setValue("city_id", "");
      setValue("pincode_id", "");
    }
  };
  const getPincode = async (id) => {
    const res = await Pincode(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, "pincode_id", false);
      setPincode(data);
      setValue("pincode_id", "");
    }
  };

  const handleEdit = async (id) => {
    const singleEdit = await getSingleAddress(id);
    reset(singleEdit?.data);
    setValue("city_id", {
      value: singleEdit?.data?.city?.id,
      label: singleEdit?.data?.city?.name,
    });
    setValue("country_id", {
      value: singleEdit?.data?.country?.id,
      label: singleEdit?.data?.country?.name,
    });
    setValue("state_id", {
      value: singleEdit?.data?.state?.id,
      label: singleEdit?.data?.state?.name,
    });
    setValue("pincode_id", {
      value: singleEdit?.data?.pincode?.id,
      label: singleEdit?.data?.pincode?.name,
    });
    setValue("address_type", {
      value: singleEdit?.data?.address_type,
      label: singleEdit?.data?.address_type,
    });
    setValue("id", singleEdit?.data?.id);
    setButton("Update Address");
    setSuccessfulAddress("Address Updated Successfully");
    // reset(singleEdit?.data)
  };

  const handleAddress = async (id, data) => {
    await setAddressId(id);
    await setAddressData(data);
  };
  useEffect(() => {
    getCountries();
    addressGet();
  }, []);

  const [showAddressFields, setShowAddressFields] = useState(false);
  return (
    <>

<ScrollToTop />
      <section className="checkout-inner-steps">
        <div className="container">
          <div className="address-holders">
            <div>
              <div className="row mb-4">
                <div className="col-lg-4  col-md-12 right_border">
                  <div>
                    <div className="main-heading-text">
                      <div className="mb-3">
                        <Form.Label>Use existing address</Form.Label>
                      </div>
                    </div>
                    <div className="Account_Address ">
                      <div className="checkout-inner-steps">
                        <Form>
                          <div className=" radio_input">
                            {address?.map((value, index) => (
                              <div className="col-md-12 d-flex">
                                <Form.Check
                                  type="radio"
                                  name="name"
                                  value={value?.id}
                                  defaultChecked={
                                    index === 0 || addressId === value?.id
                                  }
                                  onClick={() =>
                                    handleAddress(value?.id, value)
                                  }
                                />
                                <div className="radio_input_field">
                                  <div className="heading-text-holder">
                                    <div>
                                      <h3>{value?.address_type} </h3>
                                    </div>

                                    <div>
                                      <FontAwesomeIcon
                                        className="icon-img"
                                        icon={faPen}
                                        // onClick={() => {
                                        //   setModalEditAddressShow(true);
                                        // }}
                                        onClick={() => handleEdit(value?.id)}
                                      />

                                      <FontAwesomeIcon
                                        className="icon-img"
                                        icon={faTrash}
                                        onClick={() => {
                                          setAddId(value?.id);
                                          setModalDeleteAddressShow(true);
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="info-holder">
                                    <div className="text-holder">
                                      Name : {value?.name}
                                    </div>
                                    <div className="text-holder">
                                      Number : {value?.mobile_number}
                                    </div>
                                    Address :{" "}
                                    {value?.flat_home_bldg +
                                      "," +
                                      value?.col_land_strt}
                                    <p>
                                      {value?.state?.name +
                                        "," +
                                        value?.city?.name +
                                        "," +
                                        value?.pincode?.name}
                                    </p>{" "}
                                  </div>
                                </div>
                              </div>
                            ))}

                            {address.length === 0 && (
                              <h4 style={{ color: "red" }}>No Address Found</h4>
                            )}

                            {/* <div className="radio_input_field">
                          <Form.Label>Work</Form.Label>
                          <div className="flex-radio-input">
                            <Form.Check // prettier-ignore
                              type="radio"
                              name="name"
                            />

                            <Form.Control
                              type="text"
                              placeholder="2501 Better Street, Paola, KS, Kansas - 66071"
                            />
                          </div>
                        </div> */}
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 left_border">
                  <div className="main-heading-text">
                    <div className="mb-3">
                      {/* <Form.Label>Add new address</Form.Label> */}
                      <Button className=""
                        onClick={() => setShowAddressFields(!showAddressFields)}
                      >
                        {showAddressFields
                          ? "Hide Address Form"
                          : "Add New Address"}
                      </Button>
                    </div>
                  </div>

                  {showAddressFields && (
                    <div>
                      <div className="address_input">
                        <div className="flex-radio-input">
                          <Form.Label>Name*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            {...register("name", {
                              required: "Name is required",
                              pattern: {
                                value: /^[A-Za-z\s]+$/,
                                message:
                                  "Name should only contain alphabetic characters",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.name,
                              "is-valid": getValues("name"),
                            })}
                          />
                        </div>
                        {errors.name && (
                          <span
                            className="text-danger"
                            style={{ marginLeft: "160px" }}
                          >
                            {errors.name.message}
                          </span>
                        )}
                      </div>
                      <div className="address_input">
                        <div className="flex-radio-input">
                          <Form.Label>Mobile number*</Form.Label>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.mobile_number,
                              "is-valid": getValues("mobile_number"),
                            })}
                            type="text"
                            name="mobile_number"
                            placeholder="Enter Mobile Number"
                            {...register("mobile_number", {
                              required: "Contact Number is required",
                              minLength: {
                                value: 10,
                                message:
                                  "Number should be at least 10 characters",
                              },
                            })}
                            onKeyDown={(event) => {
                              if (!RegxExpression.phone.test(event.key)) {
                                if (event.key !== "Backspace") {
                                  event.preventDefault();
                                }
                              }
                            }}
                            maxLength={10}
                          />
                        </div>
                        {errors.mobile_number && (
                          <span
                            className="text-danger"
                            style={{ marginLeft: "160px" }}
                          >
                            {errors.mobile_number.message}
                          </span>
                        )}
                      </div>
                      <div className="address_input">
                        <div className="flex-radio-input">
                          <Form.Label>Name of address*</Form.Label>
                          <div className="address-name-select">
                            <Controller
                              name="address_type"
                              {...register("address_type", {
                                required: "Select Address Type",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.address_type
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={options}
                                />
                              )}
                            />
                          </div>
                        </div>
                        {errors.address_type && (
                          <span
                            className="text-danger"
                            style={{ marginLeft: "160px" }}
                          >
                            {errors.address_type.message}
                          </span>
                        )}
                      </div>

                      <div className="address_input">
                        <div className="flex-radio-input">
                          <Form.Label>Flat no./House/bldg*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Flat no./House/bldg"
                            {...register("flat_home_bldg", {
                              required: "Flat no./House/bldg required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.flat_home_bldg,
                              "is-valid": getValues("flat_home_bldg"),
                            })}
                          />
                        </div>
                        {errors.flat_home_bldg && (
                          <span
                            className="text-danger"
                            style={{ marginLeft: "160px" }}
                          >
                            {errors.flat_home_bldg.message}
                          </span>
                        )}
                      </div>

                      <div className="address_input">
                        <div className="flex-radio-input">
                          <Form.Label>Colony/street/landmark</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Colony/street/landmark"
                            {...register("col_land_strt", {
                              required: "Colony/street/landmark required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.col_land_strt,
                              "is-valid": getValues("col_land_strt"),
                            })}
                          />
                        </div>
                        {errors.col_land_strt && (
                          <span
                            className="text-danger"
                            style={{ marginLeft: "160px" }}
                          >
                            {errors.col_land_strt.message}
                          </span>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 mb-2">
                          <Controller
                            name="country_id"
                            {...register("country_id", {
                              required: "Select Country",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                placeholder="Country"
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.country_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={countries}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                  getState(selectedOption.value);
                                  setValue("country_id", selectedOption);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mb-2">
                          <Controller
                            name="state_id"
                            {...register("state_id", {
                              required: "Select Country",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                placeholder="State"
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.state_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={states}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                  getCity(selectedOption.value);
                                  setValue("state_id", selectedOption);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mb-2">
                          <Controller
                            name="city_id"
                            {...register("city_id", {
                              required: "Select City",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                placeholder="City"
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.city_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={cities}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                  getPincode(selectedOption.value);
                                  setValue("city_id", selectedOption);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mb-2">
                          <Controller
                            name="pincode_id"
                            {...register("pincode_id", {
                              required: "Select Pincode",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                placeholder="Pincode"
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.pincode_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={pincodes}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="main-btn-div mb-4">
                <Common_Button
                  onClick={handleSubmit(onSubmit)}
                  text={button}
                  className=" save-btn"
                />
              </div>
            </div>
          </div>

          <div className="main-btn-div ">
            <Link to={"/product-cart"}>
              <Common_Button text="Back" className=" back-btn" />
            </Link>

            {address.length > 0 ? (
              <Common_Button
                onClick={handleNext}
                text="Next "
                className=" save-btn"
              />
            ) : (
              <Common_Button
                onClick={() => setModalShowAddProdct(true)}
                text="Next "
                className=" save-btn"
              />
            )}
          </div>
        </div>
      </section>

      <Successfull_Modal
        message={successfulAddress}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Delete_Address
        message="Are you sure you want to delete address ? "
        show={modalDeleteAddressShow}
        onHide={() => setModalDeleteAddressShow(false)}
        addressDelete={addressDelete}
        addId={addId}
      />

      <Please_add_address
        message={"Please Add Product"}
        show={modalShowAddProduct}
        onHide={() => setModalShowAddProdct(false)}
      />
    </>
  );
};

export default OneAdress;
