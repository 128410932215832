import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./Birthday_Modal.css";
import Membership_card from "../../Common-Component/Membership_card/Membership_card";
import Membership__Modal_card from "../../Common-Component/Membership__Modal_card/Membership__Modal_card";
import { Context } from "../../../utils/context";
import { BirthdayGet, UserGet } from "../../../utils/apis/myAccount/myAccount";

function Birthday_Modal(props) {
  const { setUserData, userdata, IMG_URL } = useContext(Context);

  const [birthday, setBirthday] = useState("");

  const getUser = async () => {
    const res = await UserGet();
    if (res?.success) {
      setUserData(res.data);
    }
  };

  const getBirthdays = async () => {
    const res = await BirthdayGet();
    if (res?.success) {
      setBirthday(res.data);
    }
  };

  useEffect(() => {
    getUser();
    getBirthdays();
  }, []);
  return (
    <>
      <div className="Successfull_Modal_sec ">
        <Modal
          className="Successfull_Modal Birthday_Modal "
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="logo-holder">
              <img
                className="img-logo"
                src={
                  process.env.PUBLIC_URL + "/assets/Image/footer/footlogo.png"
                }
              />
            </div>
            <div className="content-holder">
              <div className="row me-0 ms-0">
                <div className="col-md-6">
                  <div className="Birthday-video-holder">
                    <video
                      className="video-holder"
                      controls
                      autoPlay
                      loop
                      mute
                      playsinline
                    >
                      <source
                        className="videosec"
                        // src={
                        //   process.env.PUBLIC_URL +
                        //   "/assets/video/3831835-uhd_4096_2160_25fps.mp4"
                        // }
                        src={IMG_URL + birthday?.video}
                      ></source>
                    </video>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Heading-text-holder">
                    <h3>{userdata?.first_name}</h3>
                  </div>

                  <div className="Wish-text-holder">
                    <p>Happy Birthday, {userdata?.first_name}! </p>
                    <p>{birthday?.message} From This Products</p>
                    <br></br>
                    {birthday?.birthday_products?.map((data, index) => (
                      <div className="">
                        <p>{data.product?.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Birthday_Modal;
