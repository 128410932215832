import React, { useMemo } from "react";
import Lottie from "react-lottie";
import * as animationData from "./supermarket-cart.json";

const SupermarketCart = () => {
  // Using useMemo to prevent re-creation of the options object on every render
  const defaultOptions = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }),
    []
  );

  return (
    <section className="main">
      <div className="Successfull_Lottie_icon">
        <Lottie
          className="me-auto"
          options={defaultOptions}
          isClickToPauseDisabled={true} // Optional: Prevents the animation from pausing on click
        />
      </div>
    </section>
  );
};

export default SupermarketCart;
