import React from "react";
import { Link } from "react-router-dom";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";

function Check_Your_Email() {
  return (
    <>
      <div className="LogIn">
        <div className="row bg-color me-0 ms-0">
          <div className="col-md-7 p-0">
            <div className="row justify-content-center me-0 ms-0  ">
              <div className="col-md-6">
                <div className="back_to_span">
                  <Link to={"/"}>
                    <p>Back to Home</p>
                  </Link>
                </div>

                <div className="heading-holder">
                  <h4>Check your email</h4>
                  <p>We send a password reset link to abc@gmail.com</p>
                </div>

                <div className="log_in_form">
                  <div className="btn-holder">
                    <Common_Button className="SubmitBtn" text="Open Gmail" />
                  </div>

                  <div className="create_account">
                    <div className="login_text">
                      <p>
                        Didn't receive the email?
                        <span className="login_text">Click to resend</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 p-0">
            <div className="img-section">
              <div className="img-holder">
                <img
                  className="log_in_img"
                  src={
                    process.env.PUBLIC_URL + "/assets/Image/log_in/log_in.png"
                  }
                  alt="log_in_img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Check_Your_Email;
