import React, { useContext } from "react";
import "./Purchased_Items_Modal.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Modal } from "react-bootstrap";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { useNavigate } from "react-router-dom";

function Purchased_Items_Modal(props) {
  var stepPercentage = 0;

  const navigate = useNavigate();
  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 50;
  } else if (props.currentStep === 3) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }

  const { IMG_URL, userdata } = useContext(Context);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(2);
    return `${day}/${month}/${year}`;
  }

  const createdAt = new Date(props?.data?.createdAt);
  const today = new Date();
  const differenceInTime = today.getTime() - createdAt.getTime();
  const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

  const getReturnPeriod = (membership) => {
    if (membership === "Platinum") {
      return 14;
    }
    if (membership === "Gold") {
      return 10;
    }
    return 7;
  };

  const returnPeriod = userdata?.membership
    ? getReturnPeriod(userdata.membership)
    : 7;

  return (
    <>
      <div className="Successfull_Modal_sec">
        <Modal
          className="Successfull_Modal Purchased_Items_Modal my-account"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="product-section-div">
              <div className="row">
                <div className="col-xxl-9 col-lg-8 col-md-7 col-sm-6">
                  <div>
                    <p className="date">
                      Ordered Date : {formatDate(props.data?.createdAt)}
                    </p>
                    <h6 className="items">
                      Purchased Items {props.data?.product?.name}
                    </h6>
                    <h6 className="price">
                      ₹{" "}
                      {/* {Number(props.data?.price ?? 0) +
                        (Number(props.data?.price ?? 0) *
                          Number(props.data?.tax_percent ?? 0)) /
                          100} */}
                      {props?.data?.amount}
                    </h6>
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-4 col-md-5 col-sm-6">
                  <div>
                    <p className="date">
                      Order ID : {props.data?.invoice_number}
                    </p>
                    <p className="date">
                      Payment Type : {props.data?.payment_mode}
                    </p>
                    <p className="date">
                      Payment Status :{" "}
                      {props.data?.payment_status?.status === 1
                        ? "Paid"
                        : "UnPaid"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="product-div">
                <div className="row">
                  {/* {props.data?.orders_details?.map((detail, detailIndex) => ( */}
                  <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5">
                    <div className="product">
                      <img
                        className="img-set"
                        src={
                          IMG_URL +
                          props.data?.orders_details[0]?.product?.image1
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  {/* ))} */}
                  {/* <div className=" col-xl-2 col-lg-2 col-md-3 col-sm-3  col-3   ">
                    <div className="product">
                      <img
                        className="img-set"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/My_Profile/tomato.png"
                        }
                        alt=""
                      />
                    </div>
                  </div> */}
                </div>
              </div>

              {/* <div className="pb-md-0 pb-sm-3 pb-4">
                <h6 className="order">Order Status</h6>
              </div>
              <div className="stepper-div">
                <ProgressBar
                  percent={
                    props.data?.order_status?.id === 1
                      ? 0
                      : props.data?.order_status?.id === 2
                      ? 34
                      : props.data?.order_status?.id === 3
                      ? 68
                      : props.data?.order_status?.id === 5
                      ? 100
                      : 0
                  }
                >
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Order Placed</h6>
                          
                        </div>
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Processing</h6>
                         
                        </div>
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Shipped</h6>
                          
                        </div>
                      </div>
                    )}
                  </Step>

                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Delivered</h6>
                         
                        </div>
                      </div>
                    )}
                  </Step>
                </ProgressBar>
              </div> */}
              {props.data?.order_status?.id === 4 ||
              props.data?.order_status?.id === 5 ? (
                <div className="stepper-div">
                  <h5 className="text-danger mt-4">
                    {props.data?.order_status?.id === 4
                      ? "Order Has Been Delivered"
                      : "Order Has Been Cancelled"}
                  </h5>
                </div>
              ) : (
                <>
                  <h6 className="order">Order Status</h6>
                  <div className="stepper-div">
                    <ProgressBar
                      percent={
                        props.data?.order_status?.id === 1
                          ? 0
                          : props.data?.order_status?.id === 2
                          ? 34
                          : props.data?.order_status?.id === 3
                          ? 68
                          : props.data?.order_status?.id === 4
                          ? 100
                          : 0
                      }
                    >
                      <Step>
                        {({ accomplished, index }) => (
                          <div
                            className={`indexedStep ${
                              accomplished ? "accomplished" : null
                            }`}
                          >
                            <div className="range-text-div">
                              <h6 className="placed">Order Placed</h6>
                              {/* <p className="date">20 Dec</p> */}
                            </div>
                          </div>
                        )}
                      </Step>
                      <Step>
                        {({ accomplished, index }) => (
                          <div
                            className={`indexedStep ${
                              accomplished ? "accomplished" : null
                            }`}
                          >
                            <div className="range-text-div">
                              <h6 className="placed">Processing</h6>
                              {/* <p className="date">20 Dec</p> */}
                            </div>
                          </div>
                        )}
                      </Step>

                      <Step>
                        {({ accomplished, index }) => (
                          <div
                            className={`indexedStep ${
                              accomplished ? "accomplished" : null
                            }`}
                          >
                            <div className="range-text-div">
                              <h6 className="placed">Shipped</h6>
                              {/* <p className="date">Est. 30 Dec</p> */}
                            </div>
                          </div>
                        )}
                      </Step>

                      <Step>
                        {({ accomplished, index }) => (
                          <div
                            className={`indexedStep ${
                              accomplished ? "accomplished" : null
                            }`}
                          >
                            <div className="range-text-div">
                              <h6 className="placed">Delivered</h6>
                              {/* <p className="date">Est. 30 Dec</p> */}
                            </div>
                          </div>
                        )}
                      </Step>
                    </ProgressBar>
                  </div>
                </>
              )}

              {differenceInDays <= returnPeriod &&
              props.data?.order_status?.order_status === "Deliverd" ? (
                <div className="return-button delivered-return-btn">
                  <Common_Button
                    className="return-btn"
                    text="Return"
                    onClick={() => navigate(`/order-return/${props.data?.id}`)}
                  />
                </div>
              ) : null}

              {/* </navigate> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Purchased_Items_Modal;
