import React, { useEffect } from 'react'
import HomeBanner from './HomeBanner/HomeBanner'
import ShopBySeller from './ShopBySeller/ShopBySeller'
import PopularCategories from './PopularCategories/PopularCategories'
import OurProduct from './OurProduct/OurProduct'
import SelectByCategories from './SelectByCategories/SelectByCategories'

const HomePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HomeBanner />
            <ShopBySeller />
            <SelectByCategories />
            <PopularCategories />
            <OurProduct />
        </>
    )
}

export default HomePage