import { Tab } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import Notification_Card from "../Common-Component/Notification_Card/Notification_Card";
import "./Notification.css";
import {
  AllNotificationType,
  AllNotificationTypeMesage,
} from "../../utils/apis/master/Master";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../utils/context";

const Notification = ({ handleClose, show }) => {
  const { getData, IMG_URL } = useContext(Context);
  const [getBanner, setBanner] = useState([]);
  const [message, setMessage] = useState([]);
  const [notificationid, setNotificationid] = useState();

  const getProject = async () => {
    const res = await AllNotificationType();
    if (res?.success) {
      setBanner(res?.data);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  const getNotificationMessage = async (id = null) => {
    const res = await AllNotificationTypeMesage(id);
    if (res?.success) {
      setMessage(res?.data);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  useEffect(() => {
    getProject();
  }, []);

  useEffect(() => {
    getNotificationMessage(notificationid);
  }, [notificationid]);

  return (
    <section className="Notification">
      <Offcanvas
        className="notification-offcanvas"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Notification And News</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="notifications-banner-img-holder">
            <img
              className="notifications-banner-img"
              src={
                process.env.PUBLIC_URL +
                "/assets/Image/notification/notifications-banner.png"
              }
              alt="notifications-banner-img"
            />
          </div>

          <div className="notification-tab-holder">
            <Tab.Container id="left-tabs-example" defaultActiveKey="all">
              <div className="row">
                <Nav variant="pills">
                  <Nav.Item key="all" onClick={() => setNotificationid(null)}>
                    <Nav.Link eventKey="all">All</Nav.Link>
                  </Nav.Item>
                  {getBanner?.map((item, index) => (
                    <Nav.Item
                      key={index}
                      onClick={() => setNotificationid(item?.id)}
                    >
                      <Nav.Link eventKey={item.eventKey}>{item?.name}</Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="all">
                    {message?.map((value, index) => (
                      <div className="col-md-12" key={`message-${index}`}>
                        <div style={{ marginBottom: "1rem", width: "100%" }}>
                          {value?.notifications?.map((data, idx) => {
                            const createdAt = new Date(data?.createdAt);
                            const timeString = createdAt.toLocaleTimeString();

                            return (
                              <section
                                className="Notification_Card"
                                key={`notification-${idx}`}
                              >
                                <div className="Notification_Card_holder">
                                  <div className="row card-content-holder">
                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-3">
                                      <div className="img-holder">
                                        <img
                                          className="Notification_Card-img"
                                          src={IMG_URL + data?.image}
                                          alt="Notification_Card-img"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-8">
                                      <div className="text-holder">
                                        <h3>{data?.message}</h3>
                                        <p>{timeString}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </Tab.Pane>
                  {message?.map((value, index) => (
                    <Tab.Pane
                      eventKey={getBanner[index]?.eventKey}
                      key={`pane-${index}`}
                    >
                      <div className="col-md-12">
                        <div style={{ marginBottom: "1rem", width: "100%" }}>
                          {value?.notifications?.map((data, idx) => {
                            const createdAt = new Date(data?.createdAt);
                            const timeString = createdAt.toLocaleTimeString();

                            return (
                              <section
                                className="Notification_Card"
                                key={`notification-${idx}`}
                              >
                                <div className="Notification_Card_holder">
                                  <div className="row card-content-holder">
                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-3">
                                      <div className="img-holder">
                                        <img
                                          className="Notification_Card-img"
                                          src={IMG_URL + data?.image}
                                          alt="Notification_Card-img"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-8">
                                      <div className="text-holder">
                                        <h3>{data?.message}</h3>
                                        <p>{timeString}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            );
                          })}
                        </div>
                      </div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  );
};

export default Notification;
