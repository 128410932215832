import React, { useState, useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Breadcrumb, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import Successfull_Modal from "../../../Common_modal/Successfull_Modal/Successfull_Modal";
import Return_Reason_Modal from "../../../Common_modal/Return_Reason_Modal/Return_Reason_Modal";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import { useParams } from "react-router-dom";
import { Context } from "../../../../utils/context";
import {
  singleOrderGet,
  orderReturn,
} from "../../../../utils/apis/myAccount/myAccount";

const OrderReturn = (Props) => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [modalCanleReasonShow, setModalCanleReasonShow] = useState(false);
  const hideModalWithDelay = () => {
    setTimeout(() => {
      window.location.href = "/order-return-history";
      window.location.reload();
      setModalShow(false);
    }, 3000);
  };

  const { IMG_URL } = useContext(Context);

  const [reason, setReason] = useState("");
  const [remark, setRemark] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    const data = new FormData();
    data.append("order_id", orderId);

    if (reason) {
      data.append("reason", reason);
    }
    if (remark) {
      data.append("remark", remark);
    }

    if (selectedFiles.length > 0) {
      selectedFiles.forEach((image, index) => {
        data.append(`images[${index}]`, image);
      });
    }

    try {
      const res = await orderReturn(data);
      if (res?.success) {
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          navigate("/order-return-history");
        }, 3000);
      } else {
        setErrorMessage(res?.message?.errors?.reason?.msg);
      }
    } catch (error) {
      console.error("Error submitting order return:", error);
      setErrorMessage("An error occurred. Please try again later.");
    }
  };

  const [order, setOrder] = useState([]);
  const getOrder = async () => {
    const res = await singleOrderGet(orderId);
    if (res?.success) {
      setOrder(res?.data);
    }
  };
  useEffect(() => {
    getOrder();
  }, []);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveImage = (index) => {
    const filteredFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(filteredFiles);
  };

  return (
    <>
      <div className="Order_Cancle my-account my-account-main">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/information"}>Information</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <Link>Order Return</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="product-section-div">
            <div className="product-div">
              <div className="row">
                {/*    <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-4">
                                    <div className="product">
                                        <img
                                            className="img-set"
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Image/My_Profile/cabbege.png"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </div>*/}

                {order?.orders_details?.map((items) => (
                  <div
                    className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-4"
                    key={items.product_id}
                  >
                    {items?.product?.product_images?.map((image) => (
                      <div className="product" key={image.id}>
                        <img
                          className="img-set"
                          src={IMG_URL + image?.image}
                          alt=""
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div className="text-holder">
              <p>Order ID : {order?.invoice_number}</p>
              <h4>{order.orders_details?.map((data) => data.product.name)}</h4>
              {/* <span>(Approx. 600 -1000 gm)</span> */}
              <h4>
                ₹
                {/* {Number(order?.price ?? 0) +
                  (Number(order?.price ?? 0) *
                    Number(order?.tax_percent ?? 0)) /
                    100} */}
                {order?.amount}
              </h4>
            </div>
          </div>

          <div
            className="Cancellation"
            onClick={() => setModalCanleReasonShow(true)}
          >
            <p>
              Select a reason for returning{" "}
              <FontAwesomeIcon
                className="ms-2"
                icon="fa-solid fa-angle-right"
              />
              <span style={{ color: "red" }}>{errorMessage}</span>
            </p>
          </div>

          <p className="mt-4">Upload Product Images </p>
          <div className="d-flex mt-3">
            <Form.Label className="plus-btn me-5">
              <FontAwesomeIcon icon="plus" />
              <Form.Control
                type="file"
                onChange={handleFileChange}
                multiple
                accept="image/*"
              />
            </Form.Label>
            <div className="image-holder">
              {selectedFiles.length > 0 &&
                selectedFiles.map((file, index) => (
                  <div className="file-img-holder" key={index}>
                    {/* <img
                              className="img-logo"
                              src={URL.createObjectURL(file)}
                              alt={`Uploaded File ${index + 1}`}
                            /> */}
                    <object
                      className="img-logo"
                      data={URL.createObjectURL(file)}
                      type={file.type}
                      width="59" // Set the width
                      height="58" // Set the height
                    />
                    <div
                      className="cross-overlay"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <img
                        className="img-cross"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Icon/cross-icon.png"
                        }
                        alt={`Cross Icon ${index + 1}`}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="Cancellation  Cancellation-reason">
            <p>
              Could you tell us a reason for returning (optional)
              <span>(optional)</span>
            </p>

            <Form>
              <Form.Control
                as="textarea"
                role="1"
                placeholder="Type a reason"
              />
            </Form>
            {reason && (
              <p>
                Your Reason:
                <span> {reason}</span>
              </p>
            )}

            <div className="btn-holder">
              <Link to={"/my-account"}>
                <Common_Button text="Cancel" className="cancel-btn" />
              </Link>
              <Common_Button
                //  onClick={() => { setModalShow(true); hideModalWithDelay() }}
                text="Submit"
                className="submit-btn"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </Container>
      </div>

      <Successfull_Modal
        message=" Order scheduled to return successfully! "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Return_Reason_Modal
        reason={reason}
        setReason={setReason}
        setErrorMessage={setErrorMessage}
        headername=" Select a reason for returingn"
        show={modalCanleReasonShow}
        onHide={() => setModalCanleReasonShow(false)}
        text={"return"}
      />
    </>
  );
};

export default OrderReturn;
