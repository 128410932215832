import React, { useState, useEffect, useContext } from "react";
import { Breadcrumb, Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { Context } from "../../../utils/context";
import { orderGet } from "../../../utils/apis/myAccount/myAccount";

function Main_My_Account(props) {
  const [showOrderCancel, setShowOrderCancel] = useState(false);
  const [orderId, setOrderId] = useState("");

  const handleCancelClick = (orderId) => {
    setOrderId(orderId);
    setShowOrderCancel(true);
  };

  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 50;
  } else if (props.currentStep === 3) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }
  const steps = [
    {
      links: "/order-history",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/history.png",
      goto: "Order History",
    },
    {
      links: "/my-account",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/account.png",
      goto: "My Account ",
    },
    {
      links: "/address",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/account.png",
      goto: "Add Address",
    },
    {
      links: "/ChangePassword",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/password.png",
      goto: " Change Password",
    },
  ];

  const { IMG_URL } = useContext(Context);

  const [orders, setOrder] = useState([]);
  const getOrders = async () => {
    const res = await orderGet();
    if (res?.success) {
      setOrder(res?.data);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(2);
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    getOrders();
  }, []);
  return (
    <>
      <section className="my-account my-account-main">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/information"}>Information</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <Link to={""}>My Orders </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="back-text-holder">
            <Link to={"/information"}>
              <p>Back</p>
            </Link>
          </div>
          <div>
            <div className="">
              <h2 className="title">My Orders</h2>
            </div>
            {orders?.map((value, index) => (
              <div className="product-section-div">
                {/* {value?.orders_details?.map((detail, detailIndex) => ( */}
                <div className="row">
                  <div className="col-xxl-10 col-lg-9 col-md-8 col-sm-7">
                    <div>
                      <p className="date">
                        Ordered Date : {formatDate(value?.createdAt)}
                      </p>
                      {/* <h6 className="items">Purchased Items {value?.orders_details.length}</h6> */}
                      <h6 className="price">
                        {/* {Number(value?.price ?? 0) +
                            (Number(value?.price ?? 0) *
                              Number(value?.tax_percent ?? 0)) /
                              100} */}
                        <div>
                          {value?.orders_details.map((order, index) => (
                            <div key={index}>{order.product?.name}</div>
                          ))}
                        </div>
                      </h6>
                      <h6 className="price">
                        ₹{" "}
                        {/* {Number(value?.price ?? 0) +
                            (Number(value?.price ?? 0) *
                              Number(value?.tax_percent ?? 0)) /
                              100} */}
                        {value?.amount}
                      </h6>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-5">
                    <div>
                      <p className="date">Order ID : {value?.invoice_number}</p>
                      <p className="date">
                        Payment Type : {value?.payment_mode}
                      </p>
                      <p className="date">
                        Payment Status :{" "}
                        {value?.payment_status?.status === 1
                          ? "Paid"
                          : "UnPaid"}
                      </p>
                    </div>
                  </div>
                </div>
                {/* ))} */}

                <div className="product-div">
                  <div className="row">
                    {/* {value?.orders_details?.map((detail, detailIndex) => ( */}
                    {value?.orders_details?.map(
                      (orderDetail, index) =>
                        orderDetail?.product?.image1 && (
                          <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-4">
                            <div className="product">
                              <img
                                key={index}
                                className="img-set"
                                src={`${IMG_URL}${orderDetail.product.image1}`}
                                alt={`Product ${index + 1}`}
                              />
                            </div>
                          </div>
                        )
                    )}

                    {/* ))}  */}
                  </div>
                </div>

                {value?.order_status?.id === 4 ||
                value?.order_status?.id === 5 ||
                value?.order_status?.id === 6 ? (
                  <div className="stepper-div">
                    <h5 className="text-danger mt-4">
                      {value?.order_status?.id === 4 &&
                        "Order Has Been Delivered"}

                      {value?.order_status?.id === 5 &&
                        "Order Has Been Cancelled"}

                      {value?.order_status?.id === 6 && "Order Returned"}
                    </h5>
                  </div>
                ) : (
                  <>
                    <h6 className="order">Order Status</h6>
                    <div className="stepper-div">
                      <ProgressBar
                        percent={
                          value?.order_status?.id === 1
                            ? 0
                            : value?.order_status?.id === 2
                            ? 34
                            : value?.order_status?.id === 3
                            ? 68
                            : value?.order_status?.id === 4
                            ? 100
                            : 0
                        }
                      >
                        <Step>
                          {({ accomplished, index }) => (
                            <div
                              className={`indexedStep ${
                                accomplished ? "accomplished" : null
                              }`}
                            >
                              <div className="range-text-div">
                                <h6 className="placed">Order Placed</h6>
                                {/* <p className="date">20 Dec</p> */}
                              </div>
                            </div>
                          )}
                        </Step>
                        <Step>
                          {({ accomplished, index }) => (
                            <div
                              className={`indexedStep ${
                                accomplished ? "accomplished" : null
                              }`}
                            >
                              <div className="range-text-div">
                                <h6 className="placed">Processing</h6>
                                {/* <p className="date">20 Dec</p> */}
                              </div>
                            </div>
                          )}
                        </Step>

                        <Step>
                          {({ accomplished, index }) => (
                            <div
                              className={`indexedStep ${
                                accomplished ? "accomplished" : null
                              }`}
                            >
                              <div className="range-text-div">
                                <h6 className="placed">Shipped</h6>
                                {/* <p className="date">Est. 30 Dec</p> */}
                              </div>
                            </div>
                          )}
                        </Step>

                        <Step>
                          {({ accomplished, index }) => (
                            <div
                              className={`indexedStep ${
                                accomplished ? "accomplished" : null
                              }`}
                            >
                              <div className="range-text-div">
                                <h6 className="placed">Delivered</h6>
                                {/* <p className="date">Est. 30 Dec</p> */}
                              </div>
                            </div>
                          )}
                        </Step>
                      </ProgressBar>
                    </div>
                  </>
                )}
                <div className="product-buttons">
                  {/* <Common_Button
                      className=" buy"
                      text=" Buy again"
                    /> */}

                  {value?.order_status?.id != 5 &&
                  value?.order_status?.id != 4 &&
                  value?.order_status?.id != 6 ? (
                    <Common_Button
                      onClick={() => handleCancelClick(value?.id)}
                      className="cancel"
                      text="Cancel"
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Main_My_Account;
