import React, { useContext, useEffect, useState } from "react";
import FirstVendor from "./FirstVendor/FirstVendor";
import SecondVendor from "./SecondVendor/SecondVendor";
import RateAndReview from "./RateAndReview/RateAndReview";

import { useLocation, useNavigate } from "react-router-dom";
import { getProductDetail } from "../../utils/apis/Product/Product";
import { getCartCheck } from "../../utils/apis/addToCart/addToCart";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
const VendorPage = () => {
  const { signin, variant, subvariant } = useContext(Context);

  const navigate = useNavigate();
  const [product, setProduct] = useState();
  const [cartStatus, setCartStatus] = useState(false);
  const [cartData, setCartData] = useState();
  const [variantId, setVariantId] = useState("");
  const getProduct = async () => {
    const storedProductId = localStorage.getItem("product_id");
    if (storedProductId) {
      const res = await getProductDetail(storedProductId);
      if (res?.success) {
        setProduct(res?.data);
        var response;

        if (Cookies.get("boomer_bucks_security")) {
          const data = {
            product_id: storedProductId,
            variant_id: variant?.id,
            m_variant_id: subvariant?.id,
          };
          // console.log(cartData, "cartData");

          response = await getCartCheck(data);
        } else {
          const data = {
            product_id: storedProductId,
            variant_id: variant?.id,
            m_variant_id: subvariant?.id,
          };
          response = await getCartCheck(data);
        }
        if (response?.success) {
          if (
            response?.cartItem?.variant_id === variant?.id ||
            response?.cartItem?.m_variant_id === subvariant?.id
          ) {
            setCartStatus(true);
            setCartData(response?.cartItem);
          } else {
            setCartStatus(false);
            setCartData("");
          }
        }
      }
    } else {
      navigate("/");
    }
  };
  useEffect(() => {
    getProduct();
  }, [localStorage.getItem("product_id")]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <FirstVendor
        product={product}
        cartStatus={cartStatus}
        setCartStatus={setCartStatus}
        cartData={cartData}
        getProduct={getProduct}
        setVariantId={setVariantId}
        setCartData={setCartData}
      />
      <RateAndReview product={product} />
      <SecondVendor heading="You may also like" product={product} />
    </>
  );
};

export default VendorPage;
