import React, { useState, useEffect, useContext } from "react";
import "./Purchase.css";
import { Breadcrumb, Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Order_Cancle from "../Order_Cancle/Order_Cancle";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import { Context } from "../../../../utils/context";
import { orderGet } from "../../../../utils/apis/myAccount/myAccount";

function Purchase(props) {
  const [showOrderCancel, setShowOrderCancel] = useState(false);
  const [orderId, setOrderId] = useState("");

  const handleCancelClick = (orderId) => {
    setOrderId(orderId);
    setShowOrderCancel(true);
  };

  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 50;
  } else if (props.currentStep === 3) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }
  const steps = [
    {
      links: "/order-history",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/history.png",
      goto: "Order History",
    },
    {
      links: "/my-account",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/account.png",
      goto: "My Orders ",
    },
    {
      links: "/address",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/account.png",
      goto: "Add Address",
    },
    {
      links: "/ChangePassword",
      icon: process.env.PUBLIC_URL + "/assets/Image/My_Profile/password.png",
      goto: " Change Password",
    },
  ];

  const { IMG_URL } = useContext(Context);

  const [orders, setOrder] = useState([]);
  const getOrders = async () => {
    const res = await orderGet();
    if (res?.success) {
      setOrder(res?.data);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(2);
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    getOrders();
  }, []);
  return (
    <>
      <section className="my-account">
        <div className="container">
          <div className="row mt-4 mb-4">
            {steps.map((stepes) => (
              <div className="col-lg-3 col-md-5 mb-3">
                <Link to={stepes.links}>
                  <div className="order-box">
                    <div className="row">
                      <div className="col-3">
                        <div className="order-fafa-circle">
                          <img
                            className="order-fafa-img"
                            src={stepes.icon}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-9">
                        <div className="order-text-div">
                          <p className="order-p">
                            {stepes.goto}{" "}
                            <span className="align-fafa">
                              <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="heading_holder mb-4">
            {showOrderCancel ? (
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={"/"}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => setShowOrderCancel(false)}>
                  <Link>My account</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <Link>Order Cancel</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            ) : (
              <></>
            )}
          </div>

          {showOrderCancel ? (
            <Order_Cancle
              orderId={orderId}
              onHide={() => setShowOrderCancel(false)}
            />
          ) : (
            <></>
          )}
        </div>
      </section>
    </>
  );
}

export default Purchase;
