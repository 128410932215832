import React, { useContext, useEffect, useState } from "react";
import "./SelectByCategory.css";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import VegetableTab from "./vegetable-tabs/VegetableTab";
import FruitsTab from "./Fruit-tabs/FruitsTab";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Product_Card from "../../Common-Component/Product_Card/Product_Card";
import Popular_Product_Card from "../../Common-Component/Popular_Product_Card/Popular_Product_Card";
import { Context } from "../../../utils/context";

import {
  Category,
  SubCategory,
  Products,
} from "../../../utils/apis/Product/Product";
const SeclectByCategory = ({ sellerData }) => {
  const { IMG_URL } = useContext(Context);

  const [numToShow, setNumToShow] = useState(4);
  const [activeCategory, setActive] = useState("");

  // const [categories, setCategory] = useState([]);
  // const getCategory = async () => {
  //   const res = await Category();
  //   if (res?.success) {
  //     setCategory(res?.data);
  //     handleSubCategory(res?.data[0]?.id);
  //   }
  // }

  const [subCategory, setSubCategory] = useState([]);
  const handleSubCategory = async (id, user_id) => {
    const res = await getProduct(id, user_id);
    if (res?.success) {
      setActive(id);
      setSubCategory(res?.data);
    }
  };

  const [products, setProducts] = useState([]);
  const getProduct = async (category_id, user_id) => {
    const storedSellerId = localStorage.getItem("seller_id");
    if (storedSellerId) {
      const res = await Products(category_id, "", "", user_id);
      if (res?.success) {
        setProducts(res?.data?.data);
      }
    }
  };

  // useEffect(() => {
  //   getCategory();
  // }, []);

  useEffect(() => {
    if (sellerData?.length > 0) {
      handleSubCategory(sellerData[0]?.category?.id, sellerData[0]?.user_id);
    }
    // getCategory();
  }, [sellerData]);
  return (
    <>
      <section className="select-by-category select-categories">
        <div className="container">
          <div className="row">
            <div className="heading-holder">
              <h2 className="title ">Shop by Category</h2>
            </div>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="ms-0 me-0 mb-5">
              <Nav variant="pills" className="flex-row p-0">
                <Swiper
                  className="mySwiper1"
                  breakpoints={{
                    0: {
                      slidesPerView: 1.5,
                      spaceBetween: 10,
                    },
                    420: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    486: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    1200: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                  }}
                >
                  {sellerData?.map((slide, index) => (
                    <SwiperSlide key={index} className="product-slide">
                      <Nav.Item>
                        <Nav.Link
                          className={
                            activeCategory === slide?.category?.id
                              ? "active"
                              : ""
                          }
                          onClick={() =>
                            handleSubCategory(
                              slide?.category?.id,
                              slide.user_id
                            )
                          }
                        >
                          <Popular_Product_Card
                            className="product-image"
                            content={slide?.category?.name}
                            alt={slide?.name}
                            image={IMG_URL + slide?.category?.image}
                            imgClassName="category-image"
                          />
                          <div className="triangle-right"></div>
                        </Nav.Link>
                      </Nav.Item>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Nav>
            </Row>

            {sellerData?.map((slide, index) => (
              <Tab.Content>
                <Tab.Pane eventKey={index == 0 ? "first" : slide?.name}>
                  <div className="desktop_view_product_category_slider">
                    <Row>
                      {products && products.length > 0 ? (
                        products.slice(0, numToShow).map((product, index) => {
                          const price = product?.product_multiple_mains?.[0]
                            ?.product_multiple_variants?.[0]?.s_price
                            ? product?.product_multiple_mains?.[0]
                                ?.product_multiple_variants?.[0]?.s_price
                            : product?.product_variants?.[0]?.s_price;
                          return (
                            <Col
                              key={index}
                              xxl={3}
                              xl={3}
                              lg={4}
                              md={6}
                              sm={6}
                            >
                              <Product_Card
                                image={IMG_URL + product?.image1}
                                text={product?.name}
                                product_id={product?.id}
                                subText={product?.short_description}
                                price={price}
                                newest={product?.newest}
                                variant_id={product?.product_variants?.[0]?.id}
                                discount={
                                  product?.product_variants?.[0]?.trade
                                    ? product?.product_variants?.[0]?.trade
                                    : product?.product_multiple_mains?.[0]
                                        ?.product_multiple_variants[0]?.trade
                                }
                              />
                            </Col>
                          );
                        })
                      ) : (
                        <div>No products available</div>
                      )}
                    </Row>
                  </div>

                  {/* desktop_view_product_category_slider end */}

                  {/* mobile_view_product_category_slider start */}

                  <div className="mobile_view_product_category_slider">
                    <Row className="ms-0 me-0">
                      <Swiper
                        className="select-categories-slider mySwiper"
                        modules={[Pagination]}
                        pagination={{
                          dynamicBullets: true,
                        }}
                        breakpoints={{
                          0: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          420: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          486: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          576: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                          },
                          768: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                          },
                          992: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                          },
                        }}
                      >
                        {products && products.length > 0 ? (
                          products
                            .slice(0, numToShow)
                            .map((category, index) => (
                              <SwiperSlide key={index}>
                                <Product_Card
                                  image={IMG_URL + category?.image1}
                                  text={category?.name}
                                  product_id={category?.id}
                                  subText={category?.short_description}
                                  price={category?.variants?.[0]?.s_price}
                                  discount={
                                    category?.product_variants?.[0]?.trade
                                      ? category?.product_variants?.[0]?.trade
                                      : category?.product_multiple_mains?.[0]
                                          ?.product_multiple_variants[0]?.trade
                                  }
                                />
                              </SwiperSlide>
                            ))
                        ) : (
                          <div>No products available</div>
                        )}
                      </Swiper>
                    </Row>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            ))}
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default SeclectByCategory;
