import React, { useEffect, useState } from "react";
import { useWindowScroll } from "react-use";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./scrolltotop.css";

const ScrollToTop = () => {
  const { y: pageYOffset } = useWindowScroll();
  const [visible, setVisiblity] = useState(false);
  const location = useLocation(); // Get the current location

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]); // Run this effect whenever the path changes

  useEffect(() => {
    // Show scroll-to-top button after scrolling 400px
    setVisiblity(pageYOffset > 400);
  }, [pageYOffset]);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  if (!visible) {
    return null;
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Back To Top
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="left"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <div
        className="scroll-to-top vert-move cursor-pointer text-center"
        onClick={scrollToTop}
      >
        <FontAwesomeIcon icon="fa-solid fa-arrow-up" />
      </div>
    </OverlayTrigger>
  );
};

export default ScrollToTop;
