import React from "react";
import { Modal } from "react-bootstrap";

const Map_Modal_two = (props) => {
  const mapSrc = `https://maps.google.com/maps?width=660&height=416&hl=en&q=${encodeURIComponent(
    props.address
  )}&t=&z=14&ie=UTF8&iwloc=B&output=embed`;

  return (
    <Modal
      className="Newly_launch_modal Map_Modal"
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="videomokeup">
        <div className="allmodal">
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body>
            <div className="logo-holder">
              <img
                className="img-logo"
                src={`${process.env.PUBLIC_URL}/assets/Image/footer/footlogo.png`}
                alt="Footer Logo"
              />
            </div>
            <div
              className="mapouter"
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                className="gmap_canvas"
                style={{
                  overflow: "hidden",
                  background: "none!important",
                  width: "100%",
                  height: "416px",
                }}
              >
                <iframe
                  className="gmap_iframe"
                  title={`Google Map for ${props.address}`}
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  src={mapSrc}
                  style={{
                    width: "100%",
                    height: "416px",
                  }}
                ></iframe>
                <a href="https://sprunkin.com/">Sprunki Incredibox</a>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default Map_Modal_two;
