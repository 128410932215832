import React, { useEffect, useState } from "react";
import "./Account_Address.css";
import Select from "react-select";
import { Breadcrumb, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import {
  faLocation,
  faLocationDot,
  faPen,
  faPhone,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Successfull_Modal from "../../Common_modal/Successfull_Modal/Successfull_Modal";
import Edit_Address_Modal from "../../Common_modal/Edit_Address_Modal/Edit_Address_Modal";
import Delete_Address from "../../Common_modal/Delete_Address/Delete_Address";

function Account_Address() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  const [modalShow, setModalShow] = useState(false);
  const [modalDeleteAddressShow, setModalDeleteAddressShow] = useState(false);
  const [modalEditAddressShow, setModalEditAddressShow] = useState(false);
  const hideModalWithDelay = () => {
    setTimeout(() => {
      window.location.href = "/my-account"; // Change window location to root route ("/")
      setModalShow(false);
    }, 3000); // Change the delay time as needed (3000 milliseconds = 3 seconds)
  };
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  return (
    <>
      <section className="Account_Address my-account ">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/information"}>Information</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <Link to={""}>Address </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="">
            <h2 className="title">My Address</h2>
          </div>

          <div className="checkout-inner-steps">
            <div className="container">
              <div className="address-holders">
                <Form>
                  <div className="row mb-4">
                    <div className="col-lg-6  col-md-12 right_border">
                      <div>
                        <div className=" radio_input">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="radio_input_field">
                                <div className="heading-text-holder">
                                  <div>
                                    <h3>Home</h3>
                                  </div>

                                  <div>
                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faPen}
                                      onClick={() => {
                                        setModalEditAddressShow(true);
                                      }}
                                    />

                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faTrash}
                                      onClick={() => {
                                        setModalDeleteAddressShow(true);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="info-holder">
                                  <div className="text-holder">
                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faLocationDot}
                                    />
                                    <p>
                                      Professor's Colony, Sarvottamnagar
                                      Society, Memnagar, Amdavad, Gujarat -
                                      380009
                                    </p>
                                  </div>

                                  <div className="text-holder">
                                    {" "}
                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faPhone}
                                    />{" "}
                                    <p>+91 8845234578</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="radio_input_field">
                                <div className="heading-text-holder">
                                  <div>
                                    <h3>Office</h3>
                                  </div>

                                  <div>
                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faPen}
                                      onClick={() => {
                                        setModalEditAddressShow(true);
                                      }}
                                    />

                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faTrash}
                                      onClick={() => {
                                        setModalDeleteAddressShow(true);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="info-holder">
                                  <div className="text-holder">
                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faLocationDot}
                                    />
                                    <p>
                                      1959/1, Kucha Chelan, Darya Ganj, Delhi -
                                      110002
                                    </p>
                                  </div>

                                  <div className="text-holder">
                                    {" "}
                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faPhone}
                                    />{" "}
                                    <p>+91 9956128564</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="radio_input_field">
                                <div className="heading-text-holder">
                                  <div>
                                    <h3>Home 1</h3>
                                  </div>

                                  <div>
                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faPen}
                                      onClick={() => {
                                        setModalEditAddressShow(true);
                                      }}
                                    />

                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faTrash}
                                      onClick={() => {
                                        setModalDeleteAddressShow(true);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="info-holder">
                                  <div className="text-holder">
                                    {" "}
                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faLocationDot}
                                    />
                                    <p>
                                      101/102, Surat Sadan, 85/87 Surat Street,
                                      Chinch Bunder, Mumbai - 400009
                                    </p>
                                  </div>

                                  <div className="text-holder">
                                    {" "}
                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faPhone}
                                    />{" "}
                                    <p>+91 7834009675</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6  col-md-12 left_border">
                      <div className="main-heading-text">
                        <div className="heading-holder">
                          <h4 className="form-check-label ">Add new address</h4>
                        </div>
                      </div>

                      <Form>
                        <div className="address_input">
                          <div className="flex-radio-input">
                            <Form.Label>Name of address*</Form.Label>
                            <div className="address-name-select">
                              <Select
                                classNamePrefix={"selectOptions"}
                                options={options}
                                placeholder="--- please select ---"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="address_input">
                          <div className="flex-radio-input">
                            <Form.Label>Flat no./House/bldg*</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=" Flat no./House/bldg"
                            />
                          </div>
                        </div>

                        <div className="address_input">
                          <div className="flex-radio-input">
                            <Form.Label>Colony/street/landmark</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=" Colony/street/landmark"
                            />
                          </div>
                        </div>

                        <div className="address_input">
                          <div className="flex-radio-input">
                            <Form.Label>Zip code*</Form.Label>
                            <Form.Control type="text" placeholder=" Zip code" />
                          </div>
                        </div>

                        <div className="address_input">
                          <div className="flex-radio-input">
                            <Form.Label>Mobile number*</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=" Mobile number"
                            />
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Form>
              </div>

              <div className="main-btn-div ">
                <Common_Button
                  text="Save Address "
                  className="SubmitBtn"
                  padding={"8px 50px"}
                  onClick={() => {
                    setModalShow(true);
                    hideModalWithDelay(); // Call hideModalWithDelay function when showing modal
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Successfull_Modal
        message=" Your Address has been changed successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Edit_Address_Modal
        show={modalEditAddressShow}
        onHide={() => setModalEditAddressShow(false)}
      />

      <Delete_Address
        message="Are you sure you want to delete address ? "
        show={modalDeleteAddressShow}
        onHide={() => setModalDeleteAddressShow(false)}
      />
    </>
  );
}

export default Account_Address;
