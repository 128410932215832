import React, { useContext } from "react";
import "./Scanner_Modal.css";
import { Modal } from "react-bootstrap";
import { Context } from "../../../utils/context";

function Scanner_Modal(props) {
  const { IMG_URL, postData } = useContext(Context);
  return (
    <>
      <section className="Scanner_Modal_holder">
        <Modal
          {...props}
          size="lg"
          className="Scanner_Modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            {" "}
            <h3> UPI Payment</h3>
          </Modal.Header>
          <Modal.Body>
            <div className="scanner-img-holder ">
              <img className="scanner-img" src={IMG_URL + props?.data} />
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default Scanner_Modal;
