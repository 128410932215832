import React, { useState, useEffect, useContext } from "react";
import "./History.css";
import { Breadcrumb, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Purchased_Items_Modal from "../../../Common_modal/Purchased_Items_Modal/Purchased_Items_Modal";
import Return_Items_Modal from "../../../Common_modal/Purchased_Items_Modal/PurchasedItemReturnModal";
import { Context } from "../../../../utils/context";
import { orderGet } from "../../../../utils/apis/myAccount/myAccount";
function History() {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowReturn, setModalShowReturn] = React.useState(false);
  const [modalShowOne, setModalShowTwo] = React.useState(false);

  const { IMG_URL } = useContext(Context);

  const [data, setData] = useState();

  const [orders, setOrder] = useState([]);
  const getOrders = async () => {
    const res = await orderGet();
    if (res?.success) {
      setOrder(res?.data);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  const handleDownload = async (invoice) => {
    try {
      // const response = await getData(`/order/invoice-download/${id}`);

      const filePath = invoice;

      if (!filePath) {
        console.error("File path is undefined or null");
        return;
      }

      const url = `${IMG_URL}/${filePath}`;

      // Create an invisible link element and open the file in a new tab
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank"; // Open in a new tab
      a.rel = "noopener noreferrer"; // Recommended for security reasons
      a.click();
    } catch (error) {
      console.error("Error opening file:", error);
    }
  };
  return (
    <>
      <section className="my-account  my-account-main checkout-inner-steps ">
        <div className="container">
          <div className="account-table confirm_order Product_Cart">
            <div className="heading_holder">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={"/"}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={"/information"}>Information</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <Link to={"/order-history"}>Order history</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="back-text-holder">
              <Link to={"/information"}>
                <p>Back</p>
              </Link>
            </div>

            <div className="product_table">
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Order No.</th>
                    <th>Order Date</th>
                    <th>Delivery Date</th>
                    <th>Total</th>
                    <th>Payment Type</th>
                    <th>Payment Status</th>
                    <th>Order Status</th>
                    <th>Order Progress</th>
                    <th>Download Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.map((value, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{value?.invoice_number}</td>
                      <td>{(value?.createdAt).split("T")[0]}</td>
                      <td>{value?.delivery_date?.split("T")[0]}</td>
                      <td>
                        ₹{" "}
                        {/* {Number(value?.price ?? 0) +
                          (Number(value?.price ?? 0) *
                            Number(value?.tax_percent ?? 0)) /
                            100} */}
                        {value.amount}
                      </td>
                      <td>{value?.payment_mode}</td>
                      <td className="green-text">
                        {value?.payment_status?.status == 1 ? "Paid" : "UnPaid"}
                      </td>
                      <td className="green-text">
                        {value?.order_status?.order_status}
                      </td>
                      {value?.order_status?.id === 6 ? (
                        // <div className="stepper-div">
                        //   <h5 className="text-danger mt-4">{value?.order_status?.id === 4 ? "Order Has Been Cancelled" : "Order Returned"}</h5>
                        // </div>
                        <td
                          className="view-text"
                          onClick={() => {
                            setData(value);
                            setModalShowReturn(true);
                          }}
                        >
                          <button className="viewbtttt"> View</button>
                        </td>
                      ) : (
                        <td
                          className="view-text"
                          onClick={() => {
                            setData(value);
                            setModalShow(true);
                          }}
                        >
                          <button className="viewbtttt"> View</button>
                        </td>
                      )}{" "}
                      <td>
                        <div className="d-flex">
                          <button
                            onClick={() => handleDownload(value?.invoice)}
                            className="btn btn-primary"
                          >
                            Download
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}

                  {/* <tr>
                    <td>2</td>
                    <td>#567865</td>
                    <td>19-09-21</td>
                    <td>21-09-21</td>

                    <td>₹500</td>
                    <td>-</td>
                    <td>Online</td>
                    <td className="red-text">Unpaid</td>
                    <td className="yellow-text">Ordered</td>
                    <td className="view-text" onClick={() => setModalShow(true)}>View</td>
                  </tr> */}
                </tbody>
              </Table>

              <div className="span-holder">
                {/* <span>Showing 3 of 3 entries</span> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Purchased_Items_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
      />
      <Return_Items_Modal
        show={modalShowReturn}
        onHide={() => setModalShowReturn(false)}
        data={data}
      />
    </>
  );
}

export default History;
