import React from "react";
import "./Password_Modal.css";
import { Modal } from "react-bootstrap";
import Successfull_Lottie from "../../Animation/Successfull_Lottie/Successfull_Lottie";

function RegisterModal(props) {
  return (
    <>
      <div className="Successfull_Modal_sec">
        <Modal
          className="Successfull_Modal"
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="lottie-holder">
              {" "}
              <Successfull_Lottie />
            </div>

            <p>Account Created SuccessFully...!</p>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default RegisterModal;
