import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Cookies from "js-cookie";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import CongratsSuccessmodal from "../../../Common_modal/CongratsSuccessmodal/CongratsSuccessmodal";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../../utils/context";
import ScrollToTop from "../../../ScrollTotop/ScrollTotop";

const Stepseven = ({ nextStep, prevStep, subPage }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const imageFile = watch("mou_document");
  const imageFile1 = watch("e_signature");

  const { IMG_URL, postData, getData } = useContext(Context);
  const [user_id, setUserId] = useState("");

  useEffect(() => {
    setUserId(Cookies.get("user_id"));
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (data?.id) {
      formData.append("id", data?.id);
    }
    formData.append("user_id", user_id);
    formData.append("mou_document", data?.mou_document[0]); // Ensure the first file is being sent
    formData.append("e_signature", data?.e_signature[0]); // Ensure the first file is being sent

    try {
      const res = await postData("/seller/s-mou-document", formData);
      if (res?.success) {
        setShow(true);
        setTimeout(() => {
          Cookies.remove("user_id");
          setShow(false);
          navigate("/");
        }, 6000);
      } else {
        console.error("Failed to submit data");
      }
    } catch (error) {
      console.error("An error occurred while submitting data:", error);
    }
  };

  const getEditData = async () => {
    const res = await getData(`/seller/s-mou-document/${user_id}`);
    if (res?.success) {
      reset(res?.data);
    }
  };

  useEffect(() => {
    getEditData();
  }, [user_id]);

  return (
    <>
      <ScrollToTop />
      <section className="personal-details-form">
        <div>
          <div className="form-container">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div className="stepeight">
                  <div className="dulysigned">
                    <p>Documents have been signed successfully</p>
                  </div>

                  <div className="col-md-6">
                    <div className="form-field mb-3">
                      <Form.Group controlId="mou_document">
                        <Form.Label className="required">
                          Upload Signed Documents*
                        </Form.Label>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.mou_document,
                          })}
                          type="file"
                          {...register("mou_document", {
                            // validate: async (value) => {
                            //   if (typeof value !== "string") {
                            //     const fileTypes = ["jpg", "png", "jpeg"];
                            //     const fileType = value[0]?.name?.split(".")[1];
                            //     if (!fileTypes.includes(fileType)) {
                            //       return `please upload a valid file format. (${fileTypes})`;
                            //     }
                            //   }
                            // },
                          })}
                          accept=".jpg, .jpeg, .png"
                          multiple={false}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="main-form-section mt-3">
                    <Form.Label>Image Preview </Form.Label>
                    {typeof getValues("mou_document") === "string" ? (
                      <div
                        className="image-preview-container mt-3"
                        style={{ marginLeft: "110px" }}
                      >
                        <img
                          src={IMG_URL + getValues("mou_document")}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </div>
                    ) : (
                      imageFile &&
                      imageFile.length > 0 && (
                        <div
                          className="image-preview-container mt-3"
                          style={{ marginLeft: "110px" }}
                        >
                          <img
                            src={URL.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </div>
                      )
                    )}
                  </div>

                  <div className="col-md-6">
                    <div className="form-field mb-3">
                      <Form.Group controlId="e_signature">
                        <Form.Label className="required">
                          Upload e-Signature*
                        </Form.Label>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.e_signature,
                          })}
                          type="file"
                          {...register("e_signature", {
                            // validate: async (value) => {
                            //   if (typeof value !== "string") {
                            //     const fileTypes = ["jpg", "png", "jpeg"];
                            //     const fileType = value[0]?.name?.split(".")[1];
                            //     if (!fileTypes.includes(fileType)) {
                            //       return `please upload a valid file format. (${fileTypes})`;
                            //     }
                            //   }
                            // },
                          })}
                          accept=".jpg, .jpeg, .png"
                          multiple={false}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="main-form-section mt-3">
                    <Form.Label>Image Preview </Form.Label>
                    {typeof getValues("e_signature") === "string" ? (
                      <div
                        className="image-preview-container mt-3"
                        style={{ marginLeft: "110px" }}
                      >
                        <img
                          src={IMG_URL + getValues("e_signature")}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </div>
                    ) : (
                      imageFile1 &&
                      imageFile1.length > 0 && (
                        <div
                          className="image-preview-container mt-3"
                          style={{ marginLeft: "110px" }}
                        >
                          <img
                            src={URL.createObjectURL(imageFile1[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </div>
                      )
                    )}
                  </div>

                  <div>
                    <p className="mb-4 notifytxt">
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="circleCheckicon"
                      />{" "}
                      (You will receive documents via e-mail)
                    </p>
                  </div>
                </div>

                <div className="text-end apply_now_btn">
                  <div className="registerContinueMain">
                    <Button onClick={prevStep} className="back-btn me-3">
                      Back
                    </Button>
                    <Button className="tabs-btn" type="submit">
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <CongratsSuccessmodal show={show} handleClose={handleClose} />
        </div>
      </section>
    </>
  );
};

export default Stepseven;
